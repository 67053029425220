import { httpClient } from "../../shared/services";

const { VUE_APP_VOLUNTEER_API_BASE_URL } = process.env
const volunteerApiBaseUrl = VUE_APP_VOLUNTEER_API_BASE_URL

export default {
  login(credentials) {
    const url = `${volunteerApiBaseUrl}v1/auth/login`
    return httpClient.post(url, credentials)
  },
  getMe(token) {
    const url = `${volunteerApiBaseUrl}v1/auth/me`
    return httpClient.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  },
  resetPassword(credentials) {
    const url = `${volunteerApiBaseUrl}v1/auth/update-password`
    return httpClient.patch(url, credentials)
  },
}
