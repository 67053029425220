import ZadiLayout from './layout/ZadiLayout.vue';
import auth from './helpers/auth.js';

export const zadiRoutes = [
    {
        path: '/zadi',
        name: 'Zadi',
        component: () => import('./layout/ZadiOutlit.vue'),
        children: [
            {
                path: '',
                name: 'ZadiHome',
                component: () => import('./views/Home.vue'),
                meta: { layout: ZadiLayout },
            },
            {
                path: 'profile',
                name: 'ZadiUserProfile',
                component: () => import('./views/profile/Main.vue'),
                meta: { layout: ZadiLayout },
            },
            {
                path: 'magazines',
                name: 'ZadiMagazines',
                component: () => import('./views/Magazines.vue'),
                meta: { layout: ZadiLayout },
            },
            {
                path: 'books',
                name: 'ZadiBooks',
                component: () => import('./views/Books.vue'),
                meta: { layout: ZadiLayout },
            },
            {
                path: 'books/:id',
                name: 'ZadiBookDetails',
                component: () => import('./views/BookDetails.vue'),
                meta: { layout: ZadiLayout },
            },
            {
                path: 'login',
                name: 'ZadiLogin',
                component: () => import('./views/auth/LogIn.vue'),
                meta: { layout: ZadiLayout },
            },
            {
                path: 'redirect',
                name: 'ZadiRedirect',
                component: () => import('./views/auth/Redirect.vue'),
            },
            {
                path: 'request-new-password',
                name: 'ZadiRequestNewPassword',
                component: () => import('./views/auth/RequestNewPassword.vue'),
                meta: { layout: ZadiLayout },
            },
            {
                path: 'reset-password',
                name: 'ZadiResetPassword',
                component: () => import('./views/auth/ResetPassword.vue'),
                meta: { layout: ZadiLayout },
            },
        ]
    },
]

export const zadiGuard = async (to, from, next) => {
    const authRoutesNames = ['ZadiResetPassword', 'ZadiRequestNewPassword', 'ZadiLogin', 'ZadiRedirect']
    const protectedRoutesNames = ['ZadiUserProfile']

    // const coverLoader = document.getElementById('cover-loader');
    // coverLoader.style.display = "none";

    if (to.matched[0].name != 'Zadi') {
        next()
        return
    }
    if (to.name == 'ZadiResetPassword' && !auth.isAuthenticated) {
        if (!from.name) {
            next({ name: 'ZadiHome' })
            return
        }
        next({ name: from.name })
        return
    }
    if (to.name != 'ZadiResetPassword' && auth.isTemporaryPassword && auth.isAuthenticated) {
        next({ name: 'ZadiResetPassword' })
        return
    }
    if (protectedRoutesNames.includes(to.name) && !auth.isAuthenticated) {
        next({ name: 'ZadiHome' })
        return
    }
    if (to.name != 'ZadiHome' && authRoutesNames.includes(to.name) && !auth.isTemporaryPassword && auth.isAuthenticated) {
        next({ name: 'ZadiHome' })
        return
    }
    // if (auth.isAuthenticated) {
    //     await auth.refreshToken();
    // }
    next()
};