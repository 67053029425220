import axios from 'axios'
import { apiBaseUrl } from '@/environment/environment'
import toasted from "../../utilities/toasted";
import auth from "../../../zadi/helpers/auth";
/**
 * Axios basic configuration
 * Some general configuration can be added like timeout, headers, params etc. More details can be found on https://github.com/axios/axios
 * */
const config = {
    baseURL: apiBaseUrl
}

/**
 * Creating the instance of Axios
 * It is because, in large scale application we may need to consume APIs from more than single server,
 * So, may need to create multiple http client with different config
 * Only this client will be used rather than axios in the application
 **/
const httpClient = axios.create(config)

/**
 * Auth interceptors
 * @description Configuration related to AUTH token can be done in interceptors.
 * Currenlty it is just doing nothing but idea to to show the capability of axios and its interceptors
 * In future, interceptors can be created into separate files and consumed into multiple http clients
 * @param {*} config
 */
const authInterceptor = config => {
    /** add auth token */
    return config
}

const loggerInterceptor = config => {
    /** Add logging here */
    return config
}

/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor)
httpClient.interceptors.request.use(loggerInterceptor)

// Add a request interceptor
httpClient.interceptors.request.use(function (config) {
    // Do something before request is sent

    // const isZadiReq = router.currentRoute.matched[0].name == 'Zadi';
    const isZadiReq = window.location.href.includes('zadi');
    const userToken = auth.getToken();
    const accessToken = userToken && isZadiReq ? `Bearer ${userToken}` : null;

    config.headers = {
        ...config.headers,
        ...(accessToken
            ? {
                'Authorization': accessToken,
            }
            : null),
    };

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

/** Adding the response interceptors */
httpClient.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.config && error.config.skipGlobalErrorHandler) {
            return Promise.reject(error);
        }
        switch (error.response.status) {
            case 401: {
                // const ignoreRequests = 'auth/login';
                const ignoreRequests = ['auth/login', 'auth/me'];
                const requestUrl = error?.config?.url;
                // const isIgnored = requestUrl.includes(ignoreRequests);
                const isIgnored = ignoreRequests.some(ignore => requestUrl.includes(ignore))
                if (!isIgnored) {
                    toasted.failed('انتهت الجلسة');
                    auth.logout()
                }
            }
                break;
            case 422: {
                let errors = Object.values(error.response.data.errors);
                errors = errors.flat();
                errors.forEach((value) => {
                    toasted.failed(value.toString());
                });
            }
                break;
            case 400: {
                toasted.failed(error.response.data.message);
            };
                break;
            default: {
                toasted.failed('حدث خطأ ما, يرجى المحاولة لاحقاً');
                break;
            }
        }
        return Promise.reject(error)
    }

)



export { httpClient }
